import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Card, Row, Col } from "reactstrap";
import { SEARCH_CAREERS } from "../../../utils/routes";
import {  PUBLISHED_JOB_ROLE } from '../../../utils/routes'
import CareerForm from "./CareerForm";

class CareerItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selection: null,
      items:[]
    };
    this.select = this.select.bind(this);
  }

  select(item) {
    this.setState({
      selection: item,
    });
  }

  componentDidMount() {
    // axios.get(
    //   PUBLISHED_JOB_ROLE,
    //   {withCredentials: true}
    //  )
    //  .then(res => {
    //    this.setState({
    //      items: res.data
    //    })
    //  })
    // Search function for job search { based on jobcode and jobRole}
    // jobCode is integer and jobRole is string
    // let data = {};
    // let isInteger =
    //   this.props.domain.length && /^[0-9]*$/.test(this.props.domain);
    // if (isInteger) {
    //   data.jobCode = Number(this.props.domain);
    //   data.jobRole = "null";
    // } else {
    //   data.jobCode = 0;
    //   data.jobRole = this.props.domain;
    // }

    // Search based on careers
    // axios.post(SEARCH_CAREERS, data).then((res) => {
    //   this.setState({
    //     data: res.data,
    //   });
    // });
  }

  // Render for Career page
  render() {
    const { data, selection } = this.state;
    return (
      <div className="center">
        <h5 className=" dark-blue-text center col-md-12">
          {/* <span className="dark-blue-text">{this.props.domain}</span> */}
        </h5>
        <Card className="mr-0 p-4 shadow">
          <div className="right careers-close-btn-wrapper">
            <span
              className="close"
              onClick={this.props.back}
              style={{ cursor: "pointer", color: "red", opacity: 1 }}
            >
              x
            </span>
          </div>
          <Row>
            {/* {!selection ? (
                 <Col
                    md="3"
                    sm="6"
                    // key={this.props.domain.id}
                    onClick={() => this.select(this.props.domain)}
                  >
                    <Card className="pt-2 center mb-2 shadow pointer">
                     
                      <h6>{this.props.domain.jobPosition}</h6>
                      <p>{this.props.domain.jobPosition}</p>
                      <p>{this.props.domain.jobPosition}</p>
                    </Card>
                  </Col>
              
            ) : ( */}
              <Col md="12" className="center">

                <CareerForm job={this.props.domain} />
              </Col>
            {/* )} */}
          </Row>
        </Card>
      </div>
    );
  }
}

CareerItem.propTypes = {
 
  back: PropTypes.func.isRequired,
};
export default CareerItem;
