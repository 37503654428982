import React from "react";
import { Card } from "reactstrap";

import "../../assets/css/common/DetailCard.css";

function DetailCard({ heading, visibleData, data }) {
  return (
    <Card className="detail-card">
      <h5 className="dark-blue-text bold center detail-card-head">{heading}</h5>{" "}
      <p className="justify">{visibleData}</p>
      <p className="detail-card-text">{data}</p>
    </Card>
  );
}

export default DetailCard;
