import React, { Component } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";

import { carouselData } from "./Data";
import HeroSection from "../../common/HeroSection";

class CustomeCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      animating: false,
      carouselPause: false,
    };
  }

  next = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === carouselData.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({
      activeIndex: nextIndex,
    });
  };

  previous = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? carouselData.length - 1
        : this.state.activeIndex - 1;
    this.setState({
      activeIndex: nextIndex,
    });
  };

  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({
      activeIndex: newIndex,
    });
  };

  slides = carouselData.map((item) => {
    return (
      <CarouselItem
        onExiting={() => this.setState({ animating: true })}
        onExited={() => this.setState({ animating: false })}
        key={item.src}
        className="carousel-item-div"
        data-interval="12000"
      >
        <HeroSection {...item} />
      </CarouselItem>
    );
  });

  render() {
    const { carouselPause } = this.state;

    return (
      <div id="carousel">
        <Carousel
          activeIndex={this.state.activeIndex}
          next={this.next}
          previous={this.previous}
          ride="carousel"
          pause="hover"
        >
          <CarouselIndicators
            items={carouselData}
            activeIndex={this.state.activeIndex}
            onClickHandler={this.goToIndex}
            style={{ display: "none" }}
          />
          {this.slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
      </div>
    );
  }
}
export default CustomeCarousel;
