import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import icon1 from "../../../assets/img/service-icon1.png";
import icon2 from "../../../assets/img/service-icon2.png";
import icon3 from "../../../assets/img/service-icon3.png";
import icon4 from "../../../assets/img/service-icon4.png";
import icon5 from "../../../assets/img/service-icon5.png";
import icon6 from "../../../assets/img/service-icon6.png";
import HomeCard from "../../common/HomeCard";

export default () => {
  // initiating the aos animation
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);

  return (
    <div id="services" data-aos="fade-right">
      <div className="left section-header ">
        <h4 className="dark-blue-text bold service-header">Services</h4>
      </div>
      <Row className="m-0">
        <Col
          md="4"
          className="pl-0 p-bottom-col p-top-col services-first-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon1}
            alt="Consulting"
            heading="Talents Search"
            visibleData="Accelerate and optimize your talent search"
            data=" by leveraging digital Technology & Artificial Intelligence."
            serviceIcon={true}
          />
        </Col>
        <Col
          md="4"
          className="p-bottom-col p-top-col services-second-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon2}
            alt="Consulting"
            heading="Recruitments"
            visibleData="Identify and provide the right talents at"
            data=" speed for both FTE and contract requirements."
            serviceIcon={true}
          />
        </Col>
        <Col
          md="4"
          className="pr-0 p-top-col p-bottom-col services-third-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon3}
            alt="Consulting"
            heading="Managed RPO’s "
            visibleData="We provide end-to-end ownership and"
            data=" services for RPO."
            serviceIcon={true}
          />
        </Col>
        <Col
          md="4"
          className="pl-0 p-top-col p-bottom-col services-second-row-first-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon4}
            alt="Consulting"
            heading="Fresher Hiring "
            visibleData="Identify fresh talents through Campus and"
            data=" off-Campus channels."
            serviceIcon={true}
          />
        </Col>
        <Col
          md="4"
          className="p-top-col p-bottom-col services-second-row-second-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon5}
            alt="Consulting"
            heading="Training/Up Skilling"
            visibleData="Provide customized project or technology"
            data=" or business requirements-based training for freshers and experienced resources."
            serviceIcon={true}
          />
        </Col>
        <Col
          md="4"
          className="pr-0 p-top-col p-bottom-col services-second-row-third-col"
          data-aos="fade-up"
        >
          <HomeCard
            icon={icon6}
            alt="Consulting"
            heading="Professional Services"
            visibleData="Our team of expert consultants will provide"
            data=" professional services for hiring process setups, technical screening, hiring support."
            serviceIcon={true}
          />
        </Col>
      </Row>
    </div>
  );
};
