import React from "react";
import img1 from "../../../assets/img/GGInnovation.png";
import img2 from "../../../assets/img/carousel-img-two.png";
import img3 from "../../../assets/img/carousel-img-three.png";

export const carouselData = [
  {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Innovation",
    headline: "Digital Hiring Experience Powered by innovations",
    description: (
      <>
        <p>
          {" "}
          Our talents hiring experts identifies the complex hiring challenges
          and build cost effective solutions with ease of use.
        </p>{" "}
        <p>
          {" "}
          We focus on new innovations in the areas of demand management, right
          talents identification, evaluation and onboardings.
        </p>
      </>
    ),
    buttonLabel: "Learn More",
    imgStart: "",
    img: img1,
    alt: "Credit Card",
    to: "/#products",
  },
  {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Products",
    headline: "Intelligent Talents Hiring Platform",
    description: (
      <>
        <p>
          We build intelligent hiring platform and solutions to address the
          talents acquisition challenges of Small, Medium and Large enterprises.
        </p>
        <p>
          Our platform address the business needs of experienced and fresh
          talents requirements, identification, interview scheduling and
          tracking, onboarding and management reporting's.
        </p>
      </>
    ),
    buttonLabel: "Learn More",
    imgStart: "",
    img: img2,
    alt: "Vault",
    to: "/#products",
  },
  {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Professional Services",
    headline: "Talents Identifications and Evaluations at Speed",
    description: (
      <>
        <p>
          Our professional services team is comprised of highly skilled and
          dynamic talents who can provide the right recruitment services to our
          customers.
        </p>
        <p>
          The team aligned with technology trends, business expertise, technical
          and soft skills.
        </p>
      </>
    ),
    buttonLabel: "Learn More",
    imgStart: "",
    img: img3,
    alt: "Vault",
    to: "/#services",
  },
];
