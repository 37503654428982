import React,{Component} from "react"
import axios from 'axios'
import {  PUBLISHED_JOB_ROLE } from '../../../utils/routes'
import Slider from './Slider'

class Newsticker extends Component {
  constructor(){
    super();
    this.state = {
      items: []
    }
  }

  // Get the job list data
  componentDidMount() {
    axios.get(
      PUBLISHED_JOB_ROLE,
      {withCredentials: true}
     )
     .then(res => {
       this.setState({
         items: res.data
       })
     })
       }

      
  
  
  render() {
     const {items} = this.state
    let items1 = []

    // The  items to be displayed in the dynamic moving list on the home page
    if(this.props.searchTerm){
    let result = items.filter(data=>data.jobPosition.toLowerCase().
    search(this.props.searchTerm.toLowerCase()) != -1)
   
for(let i = 0; i < result.length; i++) {
      var x = {}
      x['position'] = result[i].jobPosition
      x['description'] = result[i].jobDescription
      x['skills'] = result[i].primarySkills
      x['location'] = result[i].jobLocation
      x['category'] = result[i].jobCategory
      x['id'] = result[i].id
      items1.push(x)
    }
    }
   else if(!this.props.searchTerm){
    
   for(let i = 0; i < items.length; i++) {
      var x = {}
      x['position'] = items[i].jobPosition
      x['description'] = items[i].jobDescription
      x['skills'] = items[i].primarySkills
      x['location'] = items[i].jobLocation
      x['category'] = items[i].jobCategory
      x['id'] = items[i].id
      items1.push(x)
    }
   }
   

    // Send the items to be displayed in dynamic moving list to the Slider component
  return (
    <div className="Newsticker">
    {items1.length ?  <Slider items={ items1} setSearch={this.props.setSearch} /> : null }
    </div>
  );
}
}

export default Newsticker;