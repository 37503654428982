import React from 'react';
import ReactDOM from 'react-dom';
import PgReactHome from './PG_React_Home';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<PgReactHome />, document.getElementById('root'));
registerServiceWorker();



