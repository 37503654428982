import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";

// import images
import icon1 from "../../../assets/img/service-icon1.png";
import icon2 from "../../../assets/img/service-icon2.png";
import icon3 from "../../../assets/img/service-icon3.png";

// import video
import video from "../../../assets/img/Growth Gamut Pvt Ltd V1.mp4";

import HomeCard from "../../common/HomeCard";

const About = () => {
  // initiating the aos animation
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div id="about" data-aos="fade-right">
      <h4 className="dark-blue-text bold">About Us</h4>
      <Row className="m-0">
        <Col md="7" className="about-text-container pl-0">
          <h5>
            Started in Feb 2017, Our team of experts at Growth Gamut Pvt. Ltd.
            have been synonymous with providing bespoke recruitment solutions to
            our esteemed clients. We are focused on providing specialized
            recruitment solutions in multiple industry sectors.
          </h5>
          <h5>
            With a simplified recruitment solutions and human resource services,
            we reward and recognize success of our employees and full-time
            joiners to our customers.
          </h5>
          <h5>
            Our focus is to provide cost effective intelligent and smart end to
            end platform for corporate hiring, employee career development, Job
            identification and conduct effective trainings across industry
            business domains.
          </h5>
          <h5>
            Our mission is to build Integrated intelligent hiring platform with
            unified ranking system for Corporates, Candidates and Training
            Providers.
          </h5>
        </Col>
        <Col md="5" className="about-img-container pr-0">
          <div className="container-demo">
            <video controls="true" height="100%" width="100%">
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </Col>
      </Row>
      <Row className="about-grid-container-2 m-0">
        <Col sm="12" md="4" data-aos="fade-up" className="grid-first-col">
          <HomeCard
            icon={icon1}
            alt="first icon"
            heading="Vision"
            serviceIcon={true}
            visibleData="Provide efficient and controlled recruitment"
            data=" solutions and services to our customers with speed and quality."
          />
        </Col>
        <Col sm="12" md="4" data-aos="fade-up" className="grid-second-col">
          <HomeCard
            icon={icon2}
            alt="second icon"
            heading="Mission"
            serviceIcon={true}
            visibleData="Drive innovations through next generation"
            data=" digital solutions and AI to generate sustainable value for our customers."
          />
        </Col>
        <Col sm="12" md="4" data-aos="fade-up" className="grid-third-col">
          {" "}
          <HomeCard
            icon={icon3}
            alt="third icon"
            heading="Values"
            serviceIcon={true}
            visibleData="Our values focus on Trust, Transparency,"
            data=" Competence, Discipline, Customer Success, and Integrity."
          />
        </Col>
      </Row>
    </div>
  );
};
export default About;
