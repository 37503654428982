import { API, RS_API, CRM_API  } from "./config";

// API's for Contact Us Form
export const CONTACT_US = `${CRM_API}/v1/api/crm/inquiry/create`;

//Contact us Contact Designation field get
export const GET_DESIGNATION = `${CRM_API}/v1/api/crm/customercontactdesignation/public/designation`;

//Contact us Business Domain field get
export const GET_BUSINESS_DOMAIN = `${CRM_API}/v1/api/crm/customerbusinessdomain/public/domain`;

//Contact us Departments field get
export const GET_DEPARTMENTS = `${CRM_API}/v1/api/crm/contact-department/public/department`;

//  Careers
export const GET_CAREERS = `${API}/careers`;

export const SEARCH_CAREERS = `${API}/careers/search`;

export const APPLY_CAREERS = `${API}/apply/upload`;

export const PUBLISHED_JOB_ROLE = `${RS_API}/v1/api/published-job/public`
