import React from "react";

function NavPopover(props) {
  return (
    <>
      <div onMouseLeave={props.toggle}>
        <div
          className={`${
            props.companyPopover && "company-account-card"
          } account-card`}
        >
          {props.children}
        </div>
      </div>
    </>
  );
}

export default NavPopover;
