import React from "react";
import CustomeCarousel from "./main/CustomeCarousel";
import About from "./about/About";
import Services from "./services/Services";
import Products from "./products/Products";
import Careers from "./careers/Careers";
import Contact from "./contact_us/Contact";
import Footer from "../common/Footer";

const Collection = () => {
  return (
    <div id="home">
      <CustomeCarousel />
      <div className="social">
        <ul className="social-list">
          <li className="social-list-item">
            <a
              style={{ textDecoration: "none" }}
              href="https://www.facebook.com/Growth-Gamut-720712191626740/"
              target="_blank"
              rel="noopener noreferrer"
              className="fa fa-facebook"
            >
              {null}
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://in.pinterest.com/d422588f0df26ec0b85279ce96db81/
              "
              target="_blank"
              rel="noopener noreferrer"
              className="fa fa-pinterest"
            >
              {null}
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://twitter.com/GamutGrowth"
              target="_blank"
              rel="noopener noreferrer"
              className="fa fa-twitter"
            >
              {null}
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.youtube.com/channel/UCzn9q7BNu3ma5mVYxu6q0Zw"
              target="_blank"
              rel="noopener noreferrer"
              className="fa fa-youtube-play"
            >
              {null}
            </a>
          </li>
          <li>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.linkedin.com/company/growth-gamut/"
              target="_blank"
              rel="noopener noreferrer"
              className="fa fa-linkedin"
            >
              {null}
            </a>
          </li>
        </ul>
      </div>
      <About />
      <Products />
      <Services />
      <Careers />
      <Contact />
      <Footer />
    </div>
  );
};
export default Collection;
