import React, { Component } from "react";
import { Route } from "react-router-dom";
import Navigation from "../common/Navigation";
import Collection from "./Collection";
import Privacy from "../common/Privacy";
import TermsofUse from "../common/TermsofUse";

class Index extends Component {
  render() {
    return (
      <div>
        <Route exact path="/" component={Navigation} />
        <Route exact path="/" component={Collection} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/termsofuse" component={TermsofUse} />
      </div>
    );
  }
}
export default Index;
