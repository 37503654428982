
// Title variable
export const TITLE = process.env.REACT_APP_TITLE

// RS API
export const RS_API = process.env.REACT_APP_RS_API 

// Candidate API
export const CAN_API = process.env.REACT_APP_CAN_API 

// CRM API
export const CRM_API = process.env.REACT_APP_CRM_API 

// EMP URL
export const EMP = process.env.REACT_APP_EMP 

// PiE node api
export const API = process.env.REACT_APP_API 

// company home page
export const HOME = process.env.REACT_APP_HOME 

// Candidate Login URL
export const CANDIDATE_PORTAL = process.env.REACT_APP_CANDIDATE_PORTAL 

// Products links
// Smart Hire url
export const SMART_HIRE_URL = process.env.REACT_APP_SMART_HIRE_URL 





