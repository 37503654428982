import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  CONTACT_US,
  GET_DESIGNATION,
  GET_BUSINESS_DOMAIN,
  GET_DEPARTMENTS,
} from "../../../utils/routes";
import {
  FormValidator,
  emailChecker,
  textChecker,
} from "../../common/FormValidator";
import Notifier from "../../aside/Notifier";
import ErrorNotifier from "../../aside/ErrorNotifier";
import { HOME } from "../../../utils/config";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      org: "",
      designation: "",
      businessDomain: "",
      departments: "",
      subject: "",
      content: "",
      acceptance: "no",
      designationData: [],
      domainData: [],
      departmetsData: [],
      formErrors: {
        name: "",
        email: "",
        phone: "",
        org: "",
        businessType: "",
        businessUnit: "",
        subject: "",
        content: "",
      },
      message: null,
      error: null,
    };
    this.clearState = this.clearState.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  clearState() {
    this.setState({
      name: "",
      email: "",
      phone: "",
      org: "",
      designation: "",
      businessDomain: "",
      departments: "",
      subject: "",
      content: "",
      acceptance: "",
      designationData: [],
      domainData: [],
      departmetsData: [],
      message: null,
      error: null,
      formErrors: {
        name: "",
        email: "",
        phone: "",
        org: "",
        businessType: "",
        businessUnit: "",
        subject: "",
        content: "",
      },
    });
  }

  onChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onBlur(e) {
    const { name, value } = e.target;
    let errors = this.state.formErrors;

    switch (name) {
      case "name":
        errors.name = textChecker.test(value)
          ? ""
          : "Use only alphabets, numbers and spaces";
        break;
      case "email":
        errors.email = emailChecker.test(value)
          ? ""
          : "Please enter a proper email id";
        break;
      case "phone":
        errors.phone =
          value.length === 10 ? "" : "Please enter a proper number";
        break;
      case "org":
        errors.org = textChecker.test(value)
          ? ""
          : "Use only alphabets, numbers and spaces";
        break;
      case "subject":
        errors.subject = textChecker.test(value)
          ? ""
          : "Use only alphabets, numbers and spaces";
        break;
      case "content":
        errors.content = textChecker.test(value)
          ? ""
          : "Use only alphabets, numbers and spaces";
        break;
      default:
        break;
    }
    this.setState({
      formErrors: errors,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    if (FormValidator(this.state)) {
      let mail = {
        contactName: this.state.name,
        contactEmailId: this.state.email,
        contactNo: this.state.phone,
        organization: this.state.org,
        designation: this.state.designation,
        businessDomain: this.state.businessDomain,
        department: this.state.departments,
        subjectDesc: this.state.subject,
        messageDesc: this.state.content,
        communicationAcceptance: this.state.acceptance,
        createdBy: HOME,
      };
      axios
        .post(CONTACT_US, mail, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: "Message sent successfully",
          });
          setInterval(() => {
            this.setState({
              message: null,
            });
            this.clearState();
          }, 2000);
        })
        .catch((err) => {
          this.setState({
            error: "Message could not be delivered. Please try again later.",
          });
          setInterval(() => {
            this.setState({
              error: null,
            });
          }, 5000);
        });
    } else {
      this.setState({
        error: "Please provide the required details",
      });
      setInterval(() => {
        this.setState({
          error: null,
        });
      }, 5000);
    }
  }

  componentDidMount() {
    axios
      .get(GET_DESIGNATION, { withCredentials: true })
      .then((res) => {
        this.setState({
          designationData: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      });
    axios
      .get(GET_BUSINESS_DOMAIN, { withCredentials: true })
      .then((res) => {
        this.setState({
          domainData: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      });

    axios
      .get(GET_DEPARTMENTS, { withCredentials: true })
      .then((res) => {
        this.setState({
          departmentsData: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      });

    Aos.init({ duration: 2000 });
  }

  render() {
    const { name, email, phone, org, subject, content } = this.state.formErrors;

    return (
      <div id="contact" data-aos="fade-right">
        <div>
          <h4 className="dark-blue-text bold">Contact Us</h4>

          <div className="contact-block">
            <div className="contact-address">
              <h5 className="mb-15">Headquarters</h5>
              <p className="m-0"># 48, 4th B Main</p>
              <p className="m-0">Classic Paradise Layout</p>
              <p className="m-0">Begur Road</p>
              <p className="m-0">Bengaluru – 560068</p>
              <p className="m-0">Karnataka</p>
              <p>India.</p>
              <p className="m-0">
                <span className="pr-8">Tel</span> : <span>+919845696049</span>
                <br />
                <span className="ml-24">+918884922248</span>
              </p>
              <p>
                <span>Email</span> :{" "}
                <span>
                  <a href="mailto:info@growthgamut.com">info@growthgamut.com</a>
                </span>
              </p>
            </div>
            <div className="contact-item" data-aos="fade-up">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248923.9623618381!2d77.48260992227392!3d12.879603420376313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14cd244f0061%3A0x2dd49d32a70993e4!2sGrowth%20Gamut%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1616762291055!5m2!1sen!2sin"
                className="contact-iframe-div"
                loading="lazy"
                frameBorder="0"
                title="location"
                allowFullScreen
              />
            </div>
            <div className="contact-form" data-aos="fade-up">
              <h6 className="center contact-form-header">
                For Business Inquiries
              </h6>
              <Form onSubmit={this.onSubmit} className="pr-3 mb-3">
                <div className="form-block">
                  <FormGroup className="row">
                    <Label className="col-md-3 space-wrap">Full name*</Label>
                    <span className="col-md-1 colon">:</span>
                    <Input
                      type="text"
                      className={`contact-input col-md-8`}
                      name="name"
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      value={this.state.name}
                      required
                    />
                    <span className="col-md-4"></span>
                    <FormText className="error">{name}</FormText>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-md-3">Email*</Label>
                    <span className="col-md-1 colon">:</span>
                    <Input
                      type="email"
                      className={`contact-input col-md-8 `}
                      name="email"
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      value={this.state.email}
                      required
                    />
                    <span className="col-md-4"></span>
                    <FormText className="error">{email}</FormText>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-md-3 space-wrap">Contact No.*</Label>
                    <span className="col-md-1 colon">:</span>
                    <Input
                      type="number"
                      className={`contact-input col-md-8 `}
                      name="phone"
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      value={this.state.phone}
                      required
                    />
                    <span className="col-md-4"></span>
                    <FormText className="error">{phone}</FormText>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-md-3">Organization*</Label>
                    <span className="col-md-1 colon">:</span>
                    <Input
                      type="text"
                      className={`contact-input col-md-8 `}
                      name="org"
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      value={this.state.org}
                      required
                    />
                    <span className="col-md-4"></span>
                    <FormText className="error">{org}</FormText>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-md-3" style={{ paddingRight: "0px" }}>
                      Designation*
                    </Label>
                    <span className="col-md-1 colon">:</span>
                    <Input
                      type="select"
                      className={`contact-input col-md-8 `}
                      name="designation"
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      value={this.state.designation}
                      required
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.state.designationData &&
                        this.state.designationData.map((item) => {
                          return (
                            <option key={item.id} value={item.contactDesigName}>
                              {item.contactDesigName}
                            </option>
                          );
                        })}
                    </Input>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-md-3" style={{ paddingRight: "0px" }}>
                      Domain*
                    </Label>
                    <span className="col-md-1 colon">:</span>
                    <Input
                      type="select"
                      className={`contact-input col-md-8 `}
                      name="businessDomain"
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      value={this.state.businessDomain}
                      required
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.state.domainData &&
                        this.state.domainData.map((item) => {
                          return (
                            <option key={item.id} value={item.domainName}>
                              {item.domainName}
                            </option>
                          );
                        })}
                    </Input>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-md-3" style={{ paddingRight: "0px" }}>
                      Department*
                    </Label>
                    <span className="col-md-1 colon">:</span>
                    <Input
                      type="select"
                      className={`contact-input col-md-8 `}
                      name="departments"
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      value={this.state.departments}
                      required
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.state.departmentsData &&
                        this.state.departmentsData.map((item) => {
                          return (
                            <option
                              key={item.id}
                              value={item.department}
                            >
                              {item.department}
                            </option>
                          );
                        })}
                    </Input>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-md-3">Subject*</Label>
                    <span className="col-md-1 colon">:</span>
                    <Input
                      type="text"
                      className={`contact-input col-md-8 `}
                      name="subject"
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      value={this.state.subject}
                      required
                    />
                    <span className="col-md-4"></span>
                    <FormText className="error">{subject}</FormText>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-md-3">Message*</Label>
                    <span className="col-md-1 colon">:</span>
                    <Input
                      type="textarea"
                      className={`contact-input col-md-8`}
                      name="content"
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      value={this.state.content}
                      required
                    />
                    <span className="col-md-4"></span>
                    <FormText className="error">{content}</FormText>
                  </FormGroup>
                  <FormGroup check className="contact-checkbox-div">
                    <Label check className="contact-us-checkbox-label">
                      <Input
                        type="checkbox"
                        name="acceptance"
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        value="yes"
                        required
                      />
                      By clicking this checkbox, I am authorizing Growth Gamut
                      team to send communications to my email or contact number.
                    </Label>
                  </FormGroup>
                </div>
                <Row>
                  <Col md="4"></Col>
                  <Col md="4" className="center">
                    <button type="submit" className="contact-button pointer">
                      Send
                    </button>
                  </Col>
                  <Col md="4" className="center">
                    <button
                      type="button"
                      onClick={this.clearState}
                      className="contact-button pointer"
                    >
                      Reset
                    </button>
                  </Col>
                  {this.state.message ? (
                    <Notifier message={this.state.message} />
                  ) : null}
                  {this.state.error ? (
                    <ErrorNotifier message={this.state.error} />
                  ) : null}
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Contact;
