import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import img1 from "../../../assets/img/product-Overview-icon4.png";
import img2 from "../../../assets/img/product-Overview-icon2.png";
import { SMART_HIRE_URL } from "../../../utils/config";
import { EMP } from "../../../utils/config";
import { TECHTRAN_URL } from "../../../utils/config";
import HomeCard from "../../common/HomeCard";

export default () => {
  // initiating the aos animation
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div id="products" data-aos="fade-right">
      <div className="left section-header prod-head">
        <h4 className="dark-blue-text bold service-header">Products</h4>
      </div>
      <Row className="prod-row m-0">
        <Col md="6" className="pl-0 prod-first-col" data-aos="fade-up">
          <HomeCard
            icon={img1}
            alt="Inteli Hire Icon"
            heading="Smart Hire"
            visibleData="Accelerate and optimize your hiring solutions"
            data="by leveraging digital technology, artificial intelligence, and a highly optimized hiring process. We build intelligent hiring platform to address the challenges of small, medium, and large enterprises talent acquisitions. Our platform manages the business needs of experienced and fresh talents requirements, identification, interview scheduling and tracking, onboarding, and management reporting."
            href={SMART_HIRE_URL}
            productCard={true}
          />
        </Col>
        <Col md="6" className="prod-third-col" data-aos="fade-up">
          <HomeCard
            icon={img2}
            alt="Smart IT Icon"
            heading="Smart Assessment"
            visibleData="We build robust candidate assessment solutions and"
            data=" made hiring employees easier. Smart Hire product makes unbiased decisions on the best Candidates."
            href={EMP}
            productCard={true}
          />
        </Col>
      </Row>
    </div>
  );
};
