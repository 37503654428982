import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./assets/fonts/ubuntu.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/index.css";
import Index from "./components/home/Index";

class PG_React_Home extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route path="/" component={Index} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}
export default PG_React_Home;
