import React, { Component } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  Container,
  Collapse,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import brochure from "../../assets/files/GGBrouchure.pdf";
import GGLogo from "../../assets/img/GGLogo.jpg";
import { EMP, CANDIDATE_PORTAL } from "../../utils/config";

import { Button } from "@material-ui/core";

import NavPopover from "./NavPopover";

// imports react icons
import { FaTimes } from "react-icons/fa";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      popoverOpen: false,
      companyPopoverOpen: false,
      dropdownOpen: false,
      dropdownOpenResources: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleNavDropdown = this.toggleNavDropdown.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.toggleNavDropdownResources =
      this.toggleNavDropdownResources.bind(this);
    this.onMouseEnterResources = this.onMouseEnterResources.bind(this);
    this.onMouseLeaveResources = this.onMouseLeaveResources.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  closeMobileMenu = () => {
    this.setState({
      isOpen: false,
    });
  };

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  resetPopover = () => {
    this.setState({
      popoverOpen: false,
    });
  };

  toggleCompanyPopover = () => {
    this.setState({
      companyPopoverOpen: !this.state.companyPopoverOpen,
    });
  };

  resetCompanyPopover = () => {
    this.setState({
      companyPopoverOpen: false,
    });
  };

  resetDropdownMenu = (e) => {
    document.getElementById("activeLinkTwo").classList.remove("dropdown-menu");
  };
  // function to set the active link css
  setActiveLink = (e) => {
    // easier for me, you can change with getElementById or getElementByClassName
    const links = document.getElementsByTagName("a");

    Array.from(links).forEach((el) => el.classList.remove("active"));
    Array.from(links).forEach((el) => el.classList.remove("dropdown-active"));
    document.getElementById("activeLinkTwo").classList.remove("active");
    e.target.classList.add("active");
  };

  resetActiveLink = (e) => {
    const links = document.getElementsByTagName("a");

    Array.from(links).forEach((el) => el.classList.remove("active"));
    Array.from(links).forEach((el) => el.classList.remove("dropdown-active"));
    document.getElementById("activeLinkTwo").classList.remove("active");
    document.getElementById("resourceActiveId").classList.remove("active");
  };

  // popover active link
  setActiveLinkTwo = (e) => {
    // easier for me, you can change with getElementById or getElementByClassName
    const links = document.getElementsByTagName("a");

    Array.from(links).forEach((el) => el.classList.remove("active"));
    Array.from(links).forEach((el) => el.classList.remove("dropdown-active"));
    document.getElementById("resourceActiveId").classList.remove("active");
    document.getElementById("resourceActiveId").classList.remove("active");

    e.target.classList.add("dropdown-active");
    document.getElementById("activeLinkTwo").classList.add("active");
  };

  // active link for the resources
  setResourceActiveLink = (e) => {
    // easier for me, you can change with getElementById or getElementByClassName
    const links = document.getElementsByTagName("a");

    Array.from(links).forEach((el) => el.classList.remove("active"));
    Array.from(links).forEach((el) => el.classList.remove("dropdown-active"));
    document.getElementById("activeLinkTwo").classList.remove("active");

    e.target.classList.add("dropdown-active");
    document.getElementById("resourceActiveId").classList.add("active");
  };

  toggleNavDropdown() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  toggleNavDropdownResources() {
    this.setState((prevState) => ({
      dropdownOpenResources: !prevState.dropdownOpenResources,
    }));
  }

  onMouseEnterResources() {
    this.setState({ dropdownOpenResources: true });
  }

  onMouseLeaveResources() {
    this.setState({ dropdownOpenResources: false });
  }

  render() {
    const { isOpen } = this.state;
    /*
For Login link used the window.open('URL') for opening the PG_React_App login page in new Tab
*/
    return (
      <Navbar color="light" light fixed="top" expand="lg" id="navigation">
        <Container className="navbar-container">
          <NavbarBrand>
            <NavLink
              href="/#carousel"
              className="reset-padding"
              onClick={(e) => {
                this.resetActiveLink(e);
                this.closeMobileMenu();
              }}
            >
              <img
                src={GGLogo}
                height="50"
                style={{ margin: 0 }}
                alt="GGLogo"
                id="logo"
              />
            </NavLink>
          </NavbarBrand>
          {isOpen ? (
            <FaTimes
              onClick={this.toggle}
              className="pointer navbar-cancel-btn"
            />
          ) : (
            <NavbarToggler onClick={this.toggle} />
          )}
          <Collapse
            isOpen={this.state.isOpen}
            className={`${isOpen && "navbar-collapse-div"} `}
            navbar
          >
            <Nav  className={`${!isOpen && "mr-auto"} nav-left`} navbar>
              <NavItem className="navs">
                <NavLink
                  href="/#products"
                  onClick={(e) => {
                    this.setActiveLink(e);
                    this.closeMobileMenu();
                  }}
                >
                  Products
                </NavLink>
              </NavItem>
              <NavItem className="navs">
                <NavLink
                  href="/#services"
                  onClick={(e) => {
                    this.setActiveLink(e);
                    this.closeMobileMenu();
                  }}
                >
                  Services
                </NavLink>
              </NavItem>
              <NavItem className="navs">
                <NavLink
                  href="/#careers"
                  onClick={(e) => {
                    this.setActiveLink(e);
                    this.closeMobileMenu();
                  }}
                >
                  Careers
                </NavLink>
              </NavItem>
              {isOpen ? (
                <UncontrolledDropdown nav inNavbar className="navs">
                  <DropdownToggle nav caret>
                    Resources
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="navbar-dropdown-item">
                      <NavItem>
                        <NavLink
                          href={brochure}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ padding: ".5rem 0 .5rem .5rem" }}
                          onClick={(e) => {
                            this.resetCompanyPopover(e);
                            this.closeMobileMenu(e);
                          }}
                        >
                          Brochure
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                // <>
                //   <NavItem
                //     className="navs "
                //     onMouseEnter={() => {
                //       this.togglePopover();
                //       this.resetCompanyPopover();
                //     }}
                //   >
                //     <NavLink href="/#resources" id="resourceActiveId">
                //       Resources
                //     </NavLink>
                //   </NavItem>
                //   {this.state.popoverOpen && (
                //     <NavPopover toggle={this.togglePopover}>
                //       <NavItem>
                //         <NavLink
                //           className="popover-navlink"
                //           href={brochure}
                //           target="_blank"
                //           rel="noopener noreferrer"
                //           style={{ padding: ".5rem 0 .5rem .5rem" }}
                //           onClick={(e) => {
                //             this.setResourceActiveLink(e);
                //           }}
                //         >
                //           Brochure
                //         </NavLink>
                //       </NavItem>
                //     </NavPopover>
                //   )}
                // </>
                <Dropdown
                  nav
                  inNavbar
                  className="navs d-inline-block pl-1-rem"
                  onMouseOver={this.onMouseEnterResources}
                  onMouseLeave={this.onMouseLeaveResources}
                  isOpen={this.state.dropdownOpenResources}
                  toggle={this.toggleNavDropdownResources}
                >
                  <DropdownToggle nav caret id="resourceActiveId">
                    Resources
                  </DropdownToggle>
                  <DropdownMenu left>
                    <DropdownItem className="navbar-dropdown-item">
                      <NavItem>
                        <NavLink
                          className="popover-navlink p-0-5-rem"
                          href={brochure}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            this.setResourceActiveLink(e);
                          }}
                        >
                          Brochure
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
              {!isOpen ? (
                // <>
                //   <NavItem
                //     className="navs"
                //     onMouseEnter={() => {
                //       this.toggleCompanyPopover();
                //       this.resetPopover();
                //     }}
                //   >
                //     <NavLink href="/#company" id="activeLinkTwo">
                //       Company
                //     </NavLink>
                //   </NavItem>
                //   {this.state.companyPopoverOpen && (
                //     <div onMouseLeave={this.toggleCompanyPopover}>
                //       <div className="company-account-card account-card">
                //         <NavItem>
                //           <NavLink
                //             href="/#about"
                //             className="popover-navlink"
                //             onClick={(e) => {
                //               this.toggleCompanyPopover(e);
                //               this.setActiveLinkTwo(e);
                //             }}
                //           >
                //             About Us
                //           </NavLink>
                //         </NavItem>
                //         <NavItem>
                //           <NavLink
                //             href="/#contact"
                //             className="popover-navlink"
                //             onClick={(e) => {
                //               this.toggleCompanyPopover(e);
                //               this.setActiveLinkTwo(e);
                //             }}
                //           >
                //             Contact Us
                //           </NavLink>
                //         </NavItem>
                //         <NavItem>
                //           <NavLink
                //             className="popover-navlink pb-0-5-rem"
                //             onClick={(event) => {
                //               event.preventDefault();
                //               window.open(`${PORTAL}`);
                //               this.setActiveLinkTwo(event);
                //             }}
                //             target="_blank"
                //             rel="noopener noreferrer"
                //           >
                //             Employee Login
                //           </NavLink>
                //         </NavItem>
                //       </div>
                //     </div>
                //   )}{" "}
                // </>
                <Dropdown
                  nav
                  inNavbar
                  className="navs d-inline-block pl-1-rem"
                  onMouseOver={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggleNavDropdown}
                >
                  <DropdownToggle nav caret id="activeLinkTwo">
                    Company
                  </DropdownToggle>
                  <DropdownMenu left>
                    <DropdownItem className="navbar-dropdown-item">
                      <NavItem>
                        <NavLink
                          href="/#about"
                          className="popover-navlink p-0-5-rem"
                          onClick={(e) => {
                            this.setActiveLinkTwo(e);
                          }}
                        >
                          About Us
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                    <DropdownItem className="navbar-dropdown-item">
                      <NavItem>
                        <NavLink
                          href="/#contact"
                          className="popover-navlink p-0-5-rem"
                          onClick={(e) => {
                            this.setActiveLinkTwo(e);
                          }}
                        >
                          Contact Us
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                    <DropdownItem className="navbar-dropdown-item">
                      <NavItem>
                        <NavLink
                          className="popover-navlink p-0-5-rem"
                          onClick={(event) => {
                            event.preventDefault();
                            window.open(`${EMP}`);
                            this.setActiveLinkTwo(event);
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Employee Login
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <UncontrolledDropdown nav inNavbar className="navs">
                  <DropdownToggle nav caret id="activeLinkTwo">
                    Company
                  </DropdownToggle>
                  <DropdownMenu left>
                    <DropdownItem className="navbar-dropdown-item">
                      <NavItem>
                        <NavLink
                          href="/#about"
                          onClick={(e) => {
                            this.setActiveLinkTwo(e);
                            this.closeMobileMenu(e);
                          }}
                        >
                          About Us
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                    <DropdownItem className="navbar-dropdown-item">
                      <NavItem>
                        <NavLink
                          href="/#contact"
                          onClick={(e) => {
                            this.setActiveLinkTwo(e);
                            this.closeMobileMenu(e);
                          }}
                        >
                          Contact Us
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                    <DropdownItem className="navbar-dropdown-item">
                      <NavItem>
                        <NavLink
                          onClick={(event) => {
                            event.preventDefault();
                            window.open(`${EMP}`);
                            this.closeMobileMenu(event);
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Employee Login
                        </NavLink>
                      </NavItem>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            <Nav navbar className="nav-right">
              <NavItem
                className="navs navbar-signin-item"
                style={{ margin: "0 0 0 14px" }}
              >
                <NavLink
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(`${CANDIDATE_PORTAL}`);
                    this.closeMobileMenu(event);
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="navbar-sign-in-btn"
                  >
                    Candidate Login
                  </Button>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}
export default Navigation;
