const emailChecker = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
);

const textChecker = RegExp(
  /^(?:[A-Za-z _.]+[0-9 _.]|[0-9 _.]+[A-Za-z _.])[A-Za-z0-9 _.]*|[A-Za-z _.]+$/
);

const numChecker = RegExp(/^([0-9])|([0-9]\.[0-9])+$/);

const numberChecker = RegExp(/^([0-9])+$/);

const passChecker = RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

export let errors = {};

export let valid = false;

export const refresh = () => {
  errors = {};
};

export const Validator = ({ name, value, type, required }) => {
  errors[name] = "";

  if (required) {
    errors[name] = value.trim().length > 0 ? false : "This field is mandatory";
    if (errors[name]) {
      errors.pending = false;
    }
  }

  switch (type) {
    case "text":
      errors[name] = value.length
        ? textChecker.test(value)
          ? false
          : "Use only alphabets, numbers with alphabets or spaces"
        : errors[name];
      break;
    case "number":
      errors[name] = value.length
        ? numChecker.test(value)
          ? false
          : "Enter numbers only"
        : errors[name];
      break;
    case "tel":
      errors[name] = value.length
        ? value.length === 10
          ? numberChecker.test(value)
            ? false
            : "Enter numbers only"
          : "Mobile number must be 10 numbers"
        : errors[name];
      break;
    case "email":
      errors[name] = value.length
        ? emailChecker.test(value)
          ? false
          : "Email is not valid"
        : errors[name];
      break;
    case "textarea":
      errors[name] = value.length
        ? textChecker.test(value)
          ? false
          : "Use only alphabets, numbers with alphabets or spaces"
        : errors[name];
      break;
    case "date":
      errors[name] = errors[name].length > 0 ? errors[name] : false;
      break;
    case "time":
      errors[name] = errors[name].length > 0 ? errors[name] : false;
      break;
    case "select-one":
      errors[name] = errors[name].length > 0 ? errors[name] : false;
      break;
    case "password":
      errors[name] = value.length
        ? passChecker.test(value)
          ? false
          : "Password length atleast 8 characters (1 lowerCase, 1 upperCase, 1 number, 1 special character)"
        : errors[name];
      break;
    case "file":
      errors[name] = errors[name].length > 0 ? errors[name] : false;
      break;
    default:
      errors[name] = false;
  }

  return errors;
};

export const submitValidator = (data) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type !== "submit") {
      Validator(data[i]);
      let ob = Object.values(errors).filter((item) => item !== false);
      if (ob.length > 0) {
        if (
          (ob[0] === "" && ob.length === 1) ||
          (ob[0] === "" && ob.length === 2)
        ) {
          valid = true;
        } else {
          valid = false;
        }
      } else {
        valid = true;
      }
    }
  }
  return valid;
};
